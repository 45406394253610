import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { whatsappTemplateService } from "../../services/whatsappTemplateService";
import AppModal from "../common/AppModal";
import Loader from "../common/Loader";
import SendWhatsappCampaignForm from "../forms/SendWhatsappCampaignForm";

const SendWhatsappCampaignModal = ({
  show,
  onHide,
  showProgress,
  onSubmit,
}) => {
  const [whatsappTemplates, setWhatsappTemplates] = useState([]);
  const [fetchingWhatsappTemplates, setFetchingWhatsappTemplates] =
    useState(true);

  const fetchTemplates = async () => {
    setFetchingWhatsappTemplates(true);

    let requestBody = {
      filter: { status: "Approved" },
    };
    let { response, error } =
      await whatsappTemplateService.getWhatsappTemplates(requestBody);

    if (error) {
      toast.error(error);
      return;
    }
    const { results } = response;
    setWhatsappTemplates(results);
    setFetchingWhatsappTemplates(false);
  };

  useEffect(() => {
    if (show) {
      fetchTemplates();
    }
  }, [show]);

  return (
    <AppModal
      show={show}
      onHide={onHide}
      title={"Create Whatsapp Campaign"}
      modalBodyClassName="p-1"
    >
      <div className="px-2 py-1" style={{ minHeight: 150 }}>
        {fetchingWhatsappTemplates ? (
          <Loader />
        ) : (
          <SendWhatsappCampaignForm
            onSubmit={(value) => onSubmit([value])}
            showProgress={showProgress}
            whatsappTemplates={whatsappTemplates}
          />
        )}
      </div>
    </AppModal>
  );
};

export default SendWhatsappCampaignModal;
