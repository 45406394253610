import React, { useMemo } from "react";
import { Button } from "react-bootstrap";
import { Plus, Trash } from "react-bootstrap-icons";
import "react-datepicker/dist/react-datepicker.css";
import useLocalization from "../../../hooks/useLocalization";
import AppTable from "../../common/app-table/AppTable";
import CustomMultiSelect from "../../common/CustomMultiSelect";
import { CAMPAIGN_TRIGGER_OPTIONS } from "../../../helpers/global";

const WhatsappTemplateTable = ({
  whatsappTemplates,
  values = [],
  errors = [],
  onChange,
}) => {
  const { translate, isRTL } = useLocalization();

  const newTemplate = useMemo(
    () => ({
      template: whatsappTemplates?.[0]?._id,
      trigger: CAMPAIGN_TRIGGER_OPTIONS.ON_SIGNUP,
    }),
    [whatsappTemplates]
  );

  const handleTemplateChange = (index, field, value) => {
    const updatedValues = values.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    );
    onChange(updatedValues);
  };

  const handleRemoveTemplate = (index) => {
    const updatedValues = values.filter((_, i) => i !== index);
    onChange(updatedValues);
  };

  return (
    <div>
      <AppTable striped>
        <thead>
          <tr>
            <th className="bg-dark text-center text-white">Message template</th>
            <th className="bg-dark text-center text-white">Trigger</th>
            <th
              style={{ width: 50 }}
              className="bg-dark text-center text-white"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {values.length ? (
            values.map((template, index) => (
              <tr key={index}>
                <td align="center" className="p-0">
                  <CustomMultiSelect
                    items={whatsappTemplates.map((t) => ({
                      label: t.templateName,
                      value: t._id,
                    }))}
                    selectedItems={template["template"]}
                    onChange={(v) => handleTemplateChange(index, "template", v)}
                    isMulti={false}
                    closeMenuOnSelect
                  />
                  {errors[index]?.template && (
                    <h6 className="mb-0 tiny text-danger">
                      {errors[index].template}
                    </h6>
                  )}
                </td>
                <td align="center" className="p-0">
                  <h6 className="smallFont mb-0 mt-2">
                    {template["trigger"] ? translate(template["trigger"]) : "-"}
                  </h6>
                </td>
                <td align="center" className="p-0">
                  <Button
                    size="sm"
                    variant="outline-danger"
                    className="px-1 py-0 mt-1"
                    onClick={() => handleRemoveTemplate(index)}
                  >
                    <Trash />
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3} align="center">
                {translate("nothing_to_show")}
              </td>
            </tr>
          )}
          <tr>
            <td colSpan={3} align="right">
              <Button
                size="sm"
                variant="primary"
                className="d-flex align-items-center gap-1 text-white"
                onClick={() => onChange([...values, newTemplate])}
              >
                <Plus /> <h6 className="mb-0 smallFont">{translate("new")}</h6>
              </Button>
            </td>
          </tr>
        </tbody>
      </AppTable>
    </div>
  );
};

export default WhatsappTemplateTable;
