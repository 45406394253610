import React, { useEffect, useMemo, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { PlusCircle, XCircle } from "react-bootstrap-icons";
import ZoomIcon from "../../../assets/ZoomLogo.png";
import { isAdminOrManager } from "../../../helpers/session";
import useAuth from "../../../hooks/useAuth";
import useLocalization from "../../../hooks/useLocalization";
import Appointment from "../../appointment/Appointment";
import { useScreenWidth } from "../../../hooks/useScreenWidth";

const AppointmentModal = ({
  show,
  onHide,
  contact,
  specificContactMode = true,
  apointmentToOpen = null,
}) => {
  const [appointmentModalMeta, setAppointmentModalMeta] = useState(null);
  const [importUntrackedMeetMode, setImportUntrackedMeetMode] = useState(false);
  const { translate } = useLocalization();
  const { user } = useAuth();
  const { screenWidth } = useScreenWidth();

  const mobileViewCalendar = useMemo(() => screenWidth <= 950, [screenWidth]);

  useEffect(() => {
    setImportUntrackedMeetMode(false);
  }, [show]);

  const headerButtons = () => {
    return (
      <>
        {" "}
        {isAdminOrManager(user?.role) && (
          <Button
            variant={importUntrackedMeetMode ? "danger" : "info"}
            onClick={() => {
              setImportUntrackedMeetMode(!importUntrackedMeetMode);
            }}
            className="d-flex align-items-center gap-1 px-2"
            size="sm"
          >
            {" "}
            {importUntrackedMeetMode ? (
              <XCircle size={14} />
            ) : (
              <img
                onClick={() => {
                  setImportUntrackedMeetMode(!importUntrackedMeetMode);
                }}
                src={ZoomIcon}
                height={15}
                alt="Zoom logo"
              />
            )}
            <h6 className="mb-0 smallFont">
              {translate(
                importUntrackedMeetMode
                  ? "close_untracked_meets"
                  : "import_untracked_meets"
              )}
            </h6>
          </Button>
        )}
        {!importUntrackedMeetMode && (
          <Button
            variant="success text-white"
            onClick={() => {
              setAppointmentModalMeta({ mode: "add", appointment: null });
            }}
            className="d-flex align-items-center gap-1 px-2"
            size="sm"
          >
            <PlusCircle size={15} />{" "}
            <h6 className="mb-0 smallFont"> {translate("new")}</h6>{" "}
          </Button>
        )}
      </>
    );
  };

  return (
    <>
      <Modal show={show} onHide={onHide} centered fullscreen>
        <Modal.Header
          closeButton
          style={{ maxHeight: "30px" }}
          className="smaller"
        >
          <Modal.Title className="d-flex gap-2 align-items-center w-100">
            <h6 className="mb-0">
              {" "}
              {translate("appointment")}{" "}
              {contact
                ? `(${contact?.firstName || ""} ${contact?.lastName || ""})`
                : ""}
            </h6>
            {!mobileViewCalendar && headerButtons()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="overflow-auto p-0 position-relative">
          <Appointment
            contact={contact}
            apointmentToOpen={apointmentToOpen}
            editable={contact?.editable ?? true}
            specificContactMode={specificContactMode}
            appointmentModalMeta={appointmentModalMeta}
            setAppointmentModalMeta={setAppointmentModalMeta}
            importUntrackedMeetMode={importUntrackedMeetMode}
            setImportUntrackedMeetMode={setImportUntrackedMeetMode}
          />{" "}
          {mobileViewCalendar && (
            <Modal.Footer className="p-1">{headerButtons()}</Modal.Footer>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AppointmentModal;
