import useAuth from "../../../../hooks/useAuth";
import useTimeTracker from "../../../../hooks/useTimeTracker";
import { userRecordServices } from "../../../../services/userRecordServices";
import AppModal from "../../../common/AppModal";
import ClockInForm from "../../../forms/ClockInForm";

const ClockInAndOutModal = ({ show, onHide }) => {
  const { clockIn, clockOut, activeTimeEntry, loading } = useTimeTracker();
  const { user } = useAuth();

  console.log("user", user);

  const fetchUserRecords = async (userId) => {
    try {
      let { response, error } = await userRecordServices.getUserRecords(userId);

      if (error) {
        toast.error(error);
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClockIn = async (clockInInfo) => {
    await clockIn(clockInInfo);
    onHide();
  };

  const handleClockOut = async () => {
    await clockOut();
    await fetchUserRecords(user?._id);
    onHide();
  };

  return (
    <AppModal
      size={"sm"}
      show={show}
      onHide={onHide}
      title={"Log Time"}
      showCloseButton={!loading}
    >
      {show && (
        <ClockInForm
          onSubmit={activeTimeEntry ? handleClockOut : handleClockIn}
          showProgress={loading}
        />
      )}
    </AppModal>
  );
};

export default ClockInAndOutModal;
