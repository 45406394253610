import { CopyOutlined } from "@ant-design/icons";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, FormControl } from "react-bootstrap";
import {
  BlockquoteRight,
  BoxArrowInDownRight,
  CalendarDate,
  CardList,
  ChatQuote,
  Check,
  FileEarmarkText,
  FileText,
  Folder,
  ListCheck,
  PatchCheck,
  PersonCheck,
  Telephone,
  XLg,
} from "react-bootstrap-icons";
import ContentLoader from "react-content-loader";
import { toast } from "react-toastify";
import GoogleMailIcon from "../../../assets/GoogleMailIcon.png";
import PhoneCallIcon from "../../../assets/PhoneCallIcon.png";
import RujumAppIcon from "../../../assets/RujumAppIcon.png";
import WhatsAppIcon from "../../../assets/WhatsAppIcon.png";
import {
  BROADCAST_TYPES,
  useWebSocket,
} from "../../../context/WebSocketContext";
import { ABORT_ERROR } from "../../../helpers/api";
import { isAdminOrManager } from "../../../helpers/session";
import useAuth from "../../../hooks/useAuth";
import useContactAndDealPopup from "../../../hooks/useContactAndDealPopup";
import useLocalization from "../../../hooks/useLocalization";
import { useScreenWidth } from "../../../hooks/useScreenWidth";
import { contactService } from "../../../services/contactService";
import { referralService } from "../../../services/referralServices";
import AlertModal from "../../common/AlertModal";
import AppModal from "../../common/AppModal";
import AsyncCreatableSelect from "../../common/AsyncCreatableSelect";
import Comments from "../../common/Comments";
import PersonSelectOption from "../../common/PersonSelectOption";
import PersonSelectValue from "../../common/PersonSelectValue";
import UnderlineButton from "../../common/UnderlineButton";
import CircularProgressBar from "../../common/circular-progress";
import OverViewColumns from "../../common/overview-columns/OverViewColumns";
import MiniBrowseDeals from "../../deals/MiniBrowseDeals";
import Activity from "../activity/Activity";
import Inbounds from "../inbounds/Inbounds";
import PhoneCalls from "../phoneCalls/PhoneCalls";
import AppointmentModal from "./AppointmentModal";
// import useWebSocket from "../../../hooks/useWebSocket";

const Section = ({
  style,
  className = "",
  bodyClassName = "",
  Icon,
  title,
  children,
  noTranslate = false,
  extraHeaderContent,
}) => {
  const { translate } = useLocalization();
  return (
    <div
      style={style}
      className={`d-flex flex-column custom-card border border-light rounded-0 p-0 ${className}`}
    >
      <div
        style={{ minHeight: 30 }}
        className="d-flex justify-content-between align-items-center pb-0 border-bottom bg-gray"
      >
        <div className="d-flex align-items-center gap-2 mx-1">
          {" "}
          <Icon className="mx-1" />
          <h6 className="mid fw-bold mb-0">
            {noTranslate ? title : translate(title)}
          </h6>
        </div>
        {extraHeaderContent && extraHeaderContent()}
      </div>
      <div className={`overflow-auto flex-grow-1 px-1 ${bodyClassName}`}>
        {children}
      </div>
    </div>
  );
};

const EditContactDetailsModal = ({
  show,
  onHide,
  contact,
  originalContact,
  deleteComment,
  onNewCommentSubmit,
  onEditingCommentSubmit,
  onUpdateContactClick,
  makePhoneCall,
  onContactChange,
  onChangeLogsClick,
  updatingContact,
  newCommentText,
  setNewCommentText,
  commentsUpdating,
  editingComment,
  setEditingComment,
  deletingComment,
  setDeletingComment,
  editContactDetailsField,
  editContactAssignedField,
  editContactStatusField,
  editable = true,
  loading = false,
  sendToRujum,
  onCreateNewReferralClick,
  referrals = [],
  createdReferral,
}) => {
  const { message, clearMessage } = useWebSocket();

  const { mergeContactModalMeta, setMergeContactModalMeta } =
    useContactAndDealPopup();
  const { translate } = useLocalization();
  const { screenWidth } = useScreenWidth();
  const { user } = useAuth();
  const { followupDateChangeInfo } = useContactAndDealPopup();
  const abortControllerRef = useRef(null);

  const [appointmentModalMeta, setAppointmentModalMeta] = useState(null);
  const [duplicateContacts, setDuplicateContacts] = useState([]);

  const mobileScreen = useMemo(() => screenWidth < 800, [screenWidth]);

  const isContactModified = useMemo(() => {
    delete originalContact?.__v;
    return !_.isEqual(contact, originalContact);
  }, [contact, originalContact]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isContactModified) {
        e.preventDefault();
        e.returnValue = ""; // This line is necessary for older browsers
        return ""; // Modern browsers will show the default confirmation dialog
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isContactModified]);

  useEffect(() => {
    if (message && Boolean(show)) {
      let { type, payload } = message;

      switch (type) {
        case BROADCAST_TYPES.CONTACT_UPDATE:
          if (payload?._id === contact?._id) {
            toast.info(
              "This contact was updated by someone. You are viewing latest changes."
            );
            onContactChange({ ...payload });
          }
          break;

        case BROADCAST_TYPES.CONTACT_DELETE:
          if (payload?._id === contact?._id) {
            toast.info("This contact has been deleted by someone else.");
            onHide();
          }
          break;
      }
    }
  }, [message, show]);

  const onSaveClick = async () => {
    onUpdateContactClick();
  };

  const getDuplicateContacts = async () => {
    const { response, error } = await contactService.getDuplicateContacts({
      contactIds: [contact?._id],
    });
    if (error) {
      return toast.error(error);
    }
    if (response.length) {
      setDuplicateContacts(response[0]?.duplicates);
    }
  };

  const searchReferral = async (query) => {
    try {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      let reqBody = {
        query,
      };

      const controller = new AbortController();
      const { signal } = controller;

      abortControllerRef.current = controller;

      let { response, error } = await referralService.getReferrals(
        reqBody,
        signal
      );

      if (error === ABORT_ERROR) return;

      let { results = [] } = response;
      return results?.map((r) => ({ label: r?.name, value: r?._id, ...r }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (createdReferral) {
      onContactChange({ ...contact, referredBy: createdReferral });
    }
  }, [createdReferral]);

  useEffect(() => {
    if (contact?._id && show) {
      getDuplicateContacts();
    } else {
      setDuplicateContacts([]);
    }
  }, [contact?._id, show]);

  useEffect(() => {
    if (followupDateChangeInfo) {
      const { contact: updatedContact, followupDate } = followupDateChangeInfo;
      if (updatedContact?._id === contact?._id)
        onContactChange({ ...contact, followupDate });
    }
  }, [followupDateChangeInfo]);

  return (
    <AppModal
      show={show}
      onHide={onHide}
      fullscreen
      hideHeader
      modalBodyClassName="p-0"
    >
      {" "}
      <>
        {" "}
        <div className="d-flex w-100 justify-content-between p-1">
          <div className="d-flex gap-2">
            {contact && (
              <Button
                className="px-1 py-0 d-flex justify-content-between border-light align-items-center"
                variant={"light-gray"}
                onClick={onChangeLogsClick}
                disabled={updatingContact || commentsUpdating}
              >
                <FileText size={13} className={`text-primary`} />{" "}
                <span className="smallFont mx-1">
                  {translate("change_logs")}{" "}
                </span>
              </Button>
            )}

            {duplicateContacts?.length > 0 && isAdminOrManager(user?.role) && (
              <Button
                className="px-1 py-0 d-flex justify-content-between border-light align-items-center "
                variant={"light-gray"}
                onClick={() =>
                  setMergeContactModalMeta({
                    duplicateContacts: duplicateContacts,
                    baseContact: contact,
                  })
                }
                disabled={updatingContact || commentsUpdating}
              >
                <CopyOutlined size={13} className={`text-success`} />{" "}
                <span className="smallFont mx-1">
                  {translate("merge_contacts")}{" "}
                </span>
              </Button>
            )}
          </div>
          <div className="d-flex">
            <Button
              variant="danger"
              className="text-white px-2 py-0 mx-1"
              size="sm"
              disabled={updatingContact}
              onClick={onHide}
            >
              <span className="py-1">
                {translate("close")} <XLg size={12} />
              </span>
            </Button>
            {editable && !loading && Boolean(contact) && (
              <Button
                variant={isContactModified ? "success" : "secondary"}
                className="text-white px-2 py-0 mx-1"
                size="sm"
                disabled={updatingContact || !isContactModified}
                onClick={onSaveClick}
              >
                {updatingContact ? (
                  <span className="mx-1 px-1 text-white">
                    <CircularProgressBar size={12} />
                    <span className="mx-1 smallFont">
                      {translate("please_wait")}
                    </span>
                  </span>
                ) : (
                  <span className="py-1">
                    {translate("save")} <Check size={15} />
                  </span>
                )}
              </Button>
            )}
          </div>
        </div>
        {loading || !Boolean(contact) ? (
          <ContentLoader className="p-2 w-50">
            {" "}
            <rect x="0" y="0" rx="4" ry="4" width="100%" height="15px" />
            <rect x="0" y="20" rx="4" ry="4" width="90%" height="15px" />
            <rect x="0" y="40" rx="4" ry="4" width="80%" height="15px" />
          </ContentLoader>
        ) : (
          <>
            {" "}
            {contact && (
              <div
                className="position-relative d-flex flex-wrap bg-secondary"
                style={{ height: `calc(100vh - 32px)` }}
              >
                <div
                  style={{
                    padding: 1,
                    width: mobileScreen ? "100%" : "18%",
                  }}
                  className="d-flex flex-column h-100"
                >
                  {/* details */}
                  <Section
                    className="h-100"
                    Icon={BlockquoteRight}
                    title={"details"}
                    bodyClassName="bg-light"
                  >
                    {" "}
                    <OverViewColumns
                      parentContainerClassName="mid m-0 p-0"
                      inputCellStyle={{ fontSize: 14 }}
                      cellSizeMD={12}
                      data={contact}
                      onDataChange={(newData) => {
                        onContactChange({ ...contact, ...newData });
                      }}
                      fields={editContactDetailsField}
                      isUpdating={updatingContact}
                      alwaysInEditMode={false}
                      editable={editable}
                    />
                  </Section>

                  {/* Assigned to */}
                  <Section
                    style={{ marginTop: 2, height: "230px" }}
                    Icon={PersonCheck}
                    title={"assigned_to"}
                    bodyClassName="bg-light"
                  >
                    {isAdminOrManager(user?.role) && (
                      <OverViewColumns
                        parentContainerClassName="mid m-0 p-0"
                        cellSizeMD={12}
                        data={{
                          salesperson:
                            contact?.salesperson?.map((s) => s?._id || s) || [],
                          financialStrategist:
                            contact?.financialStrategist?.map(
                              (s) => s?._id || s
                            ) || [],
                          realEstateAnalyst:
                            contact?.realEstateAnalyst?.map(
                              (s) => s?._id || s
                            ) || [],
                        }}
                        onDataChange={(newData) => {
                          onContactChange({ ...contact, ...newData });
                        }}
                        fields={editContactAssignedField}
                        isUpdating={updatingContact}
                        alwaysInEditMode={false}
                        editable={editable}
                      />
                    )}

                    <div className="p-1">
                      <h6 className="smallFont">Referral</h6>{" "}
                      <AsyncCreatableSelect
                        CustomSingleValueComponent={PersonSelectValue}
                        CustomOptionComponent={PersonSelectOption}
                        defaultItems={referrals?.map((r) => ({
                          label: r?.name,
                          value: r?._id,
                          ...r,
                        }))}
                        selectedItems={contact?.referredBy}
                        onChange={(v) => {
                          onContactChange({ ...contact, referredBy: v });
                        }}
                        onCreateOption={onCreateNewReferralClick}
                        loadOptions={searchReferral}
                        isClearable
                      />{" "}
                    </div>
                  </Section>
                </div>

                <div
                  style={{
                    padding: 1,
                    width: mobileScreen ? "100%" : "42%",
                  }}
                  className="d-flex flex-column h-100"
                >
                  {/* status */}
                  <Section
                    style={{ minHeight: "150px" }}
                    Icon={PatchCheck}
                    title={"status"}
                    bodyClassName="bg-light pt-1"
                  >
                    {" "}
                    <OverViewColumns
                      parentContainerClassName="mid m-0 p-0"
                      inputCellStyle={{ fontSize: 14 }}
                      cellSizeMD={12}
                      data={contact}
                      onDataChange={(newData) => {
                        onContactChange({ ...contact, ...newData });
                      }}
                      fields={editContactStatusField}
                      isUpdating={updatingContact}
                      alwaysInEditMode={false}
                      editable={editable}
                    />
                  </Section>
                  {/* Description */}
                  <Section
                    style={{ marginTop: 2, height: 120 }}
                    Icon={CardList}
                    className=" "
                    title={translate("description")}
                    noTranslate
                    bodyClassName="bg-light py-1"
                  >
                    <FormControl
                      value={contact?.description}
                      onChange={(e) =>
                        onContactChange({
                          ...contact,
                          description: e.target.value,
                        })
                      }
                      size="sm"
                      placeholder="Type your description here..."
                      as={"textarea"}
                      rows={2}
                    />
                  </Section>
                  {/* Comments */}
                  <Section
                    style={{ marginTop: 2 }}
                    Icon={ChatQuote}
                    className="h-50"
                    title={translate("comments", {
                      count: contact?.comments?.length,
                    })}
                    noTranslate
                    bodyClassName="bg-light"
                  >
                    {" "}
                    <div className="overflow-auto flex-grow-1 p-1">
                      <Comments
                        comments={contact.comments}
                        text={newCommentText}
                        onTextChange={setNewCommentText}
                        onCommentSubmit={onNewCommentSubmit}
                        commentsUpdating={commentsUpdating}
                        editable={editable}
                        editingComment={editingComment}
                        onEditingCommentUpdate={(text) =>
                          setEditingComment({ ...editingComment, text })
                        }
                        onCommentEditClick={(comment) =>
                          setEditingComment({ ...comment })
                        }
                        onCommentEditCancelClick={() => setEditingComment(null)}
                        onCommentEditSaveClick={onEditingCommentSubmit}
                        onCommentDeleteClick={(comment) =>
                          setDeletingComment({ ...comment })
                        }
                      />
                    </div>
                  </Section>

                  {/* Activity */}
                  <Section
                    style={{ marginTop: 2 }}
                    className="h-25"
                    Icon={ListCheck}
                    title={"activity"}
                    extraHeaderContent={
                      contact?.driveFolder
                        ? () => {
                            return (
                              <UnderlineButton
                                iconSize={15}
                                fontSize="smallFont"
                                Icon={Folder}
                                text={"Folder"}
                                target={"_blank"}
                                href={`https://drive.google.com/drive/folders/${contact?.driveFolder}`}
                              />
                            );
                          }
                        : null
                    }
                  >
                    <Activity contact={contact} />
                  </Section>
                </div>
                <div
                  style={{
                    padding: 1,
                    width: mobileScreen ? "100%" : "40%",
                  }}
                  className="d-flex flex-column h-100"
                >
                  {/* Inbounds */}
                  <Section
                    Icon={BoxArrowInDownRight}
                    title={translate(
                      contact?.inbounds?.length
                        ? "inbounds"
                        : "inbounds_no_count",
                      {
                        count: contact?.inbounds?.length,
                      }
                    )}
                    noTranslate
                    style={{ height: "33%" }}
                    extraHeaderContent={() => (
                      <div className="d-flex flex-wrap align-items-center">
                        {[
                          {
                            label: "call",
                            iconVariant: "success",
                            imgSrc: PhoneCallIcon,
                            onClick: () =>
                              makePhoneCall(contact.phoneNumbers?.[0]),
                            disabled: false,
                          },
                          {
                            label: "whatsapp",
                            url: `https://wa.me/+972${contact.phoneNumbers?.[0]}`,
                            iconVariant: "success",
                            imgSrc: WhatsAppIcon,
                          },
                          {
                            label: "email",
                            url: `mailto:${contact.emails?.[0]}`,
                            iconVariant: "primary",
                            imgSrc: GoogleMailIcon,
                          },
                          {
                            label: "set_appointment",
                            iconVariant: "info",
                            Icon: CalendarDate,
                            onClick: () => setAppointmentModalMeta({}),
                            disabled: false,
                          },
                          {
                            label: "send_to_rujum",
                            iconVariant: "purple",
                            imgSrc: RujumAppIcon,
                            onClick: () => sendToRujum(contact),
                            disabled: false,
                          },
                        ].map(
                          ({
                            label,
                            url,
                            iconVariant,
                            Icon,
                            imgSrc,
                            onClick,
                            disabled,
                          }) => (
                            <Button
                              className="px-1 py-1 d-flex justify-content-between border-light align-items-center mx-1"
                              key={`${label}-${url}`}
                              variant={"light-gray"}
                              href={url}
                              target="_blank"
                              onClick={onClick}
                              disabled={disabled}
                            >
                              {imgSrc ? (
                                <img
                                  size={13}
                                  src={imgSrc}
                                  height={20}
                                  width={20}
                                  alt="icon"
                                />
                              ) : (
                                <Icon className={`text-${iconVariant}`} />
                              )}

                              <span className="smallFont mx-1">
                                {translate(label)}{" "}
                              </span>
                            </Button>
                          )
                        )}
                      </div>
                    )}
                  >
                    {" "}
                    <Inbounds contact={contact} />
                  </Section>

                  {/* Deals */}
                  <Section
                    noTranslate
                    Icon={FileEarmarkText}
                    title={translate(
                      contact?.contracts?.length ? "deals_count" : "deals",
                      {
                        count: contact?.contracts?.length,
                      }
                    )}
                    className="h-50"
                    style={{ height: "34%", marginTop: 2 }}
                  >
                    <MiniBrowseDeals
                      contact={contact}
                      editable={editable}
                      onDealsChange={(contracts) => {
                        onContactChange({ ...contact, contracts });
                      }}
                    />
                  </Section>

                  {/* Phone Calls */}
                  <Section
                    style={{ height: "33%", marginTop: 2 }}
                    title={translate(
                      contact?.phoneCalls?.length
                        ? "phone_calls_count"
                        : "phone_calls",
                      {
                        count: contact?.phoneCalls?.length,
                      }
                    )}
                    Icon={Telephone}
                    noTranslate
                  >
                    {" "}
                    <PhoneCalls contact={contact} columnToHide={["callType"]} />
                  </Section>
                </div>
              </div>
            )}
          </>
        )}{" "}
      </>
      {contact?.comments?.length > 0 && (
        <AlertModal
          show={Boolean(deletingComment)}
          onHide={() => setDeletingComment(null)}
          onContinueClick={deleteComment}
          onDismissClick={() => setDeletingComment(null)}
          alertText={translate("are_you_sure_delete_comment")}
          showProgress={commentsUpdating}
        />
      )}
      <AppointmentModal
        show={Boolean(appointmentModalMeta)}
        onHide={() => setAppointmentModalMeta(null)}
        contact={contact}
        specificContactMode
      />
    </AppModal>
  );
};

export default EditContactDetailsModal;
