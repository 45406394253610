import useLocalization from "../../../hooks/useLocalization";
import AppModal from "../../common/AppModal";
import CircularProgressBar from "../../common/circular-progress";
import AddEditEventForm from "../../forms/AddEditEventForm";

const AddEditEventModal = ({
  show,
  initialValues,
  editMode,
  onHide,
  showProgress,
  activeTrailLists,
  whatsappTemplates,
  campaigns,
  onSubmit,
}) => {
  const { translate } = useLocalization();

  const onFormSubmit = (event) => {
    let urls = (event.urls || []).map((u) => u.toLowerCase());
    urls = [...new Set(urls)];

    const eventToSubmit = {
      ...event,
      urls,
      facebookCampaigns: event.facebookCampaignIds.map((id) =>
        campaigns.find((l) => l.id == id)
      ),
    };

    onSubmit && onSubmit(eventToSubmit);
  };

  return (
    <AppModal
      size={"lg"}
      show={show}
      onHide={onHide}
      title={editMode ? translate(`update_event`) : translate(`add_new_event`)}
      showCloseButton={!showProgress}
    >
      <AddEditEventForm
        initialValues={initialValues}
        activeTrailListOptions={activeTrailLists}
        whatsappTemplates={whatsappTemplates}
        facebookCampaignOptions={campaigns}
        showProgress={showProgress}
        editMode={editMode}
        onSubmit={onFormSubmit}
      />
    </AppModal>
  );
};

export default AddEditEventModal;
