import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { convertZoomRecordToAppAppointment } from "../../../helpers/global";
import { isAdminOrManager } from "../../../helpers/session";
import useAppChoices from "../../../hooks/useAppChoices";
import useAuth from "../../../hooks/useAuth";
import useLocalization from "../../../hooks/useLocalization";
import { availabilityService } from "../../../services/availabilityService";
import { googleDriveServices } from "../../../services/googleDriveServices";
import AlertModal from "../../common/AlertModal";
import AppointmentDetailModal from "../../common/AppointmentDetailModal";
import Loader from "../../common/Loader";
import MeetingCard from "../../common/MeetingCard";
import SelectContactModal from "../../common/SelectContactModal";
import ContactAppointmentModal from "./ContactAppointmentModal";

const ImportZoomRecords = ({
  searchQuery,
  checkedParticipants,
  appointmentTypeFilter,
  onImportUntrackedZoomMeet,
  contacts,
  loadingContacts,
  setContactQuery,
  handleAppointmentClick,
}) => {
  const { translate } = useLocalization();
  const { user } = useAuth();
  const users = useAppChoices("users");

  // Import untracked Zoom meetings
  const [fetchingUntrackedZoomMeetings, setFetchingUntrackedZoomMeetings] =
    useState(true);
  const [originalUntrackedZoomMeets, setOriginalUntrackedZoomMeets] = useState(
    []
  );
  const [untrackedZoomMeets, setUntrackedZoomMeets] = useState([]);

  const [contactAppointmentsModalMeta, setContactAppointmentsModalMeta] =
    useState(null);
  const [deleteUntrackedZoomMeetMeta, setDeleteUntrackedZoomMeetMeta] =
    useState(null);

  const [selectContactModalMeta, setSelectContactModalMeta] = useState(null);

  const [appointmentDetailModalMeta, setAppointmentDetailModalMeta] =
    useState(null);

  const getZoomRecords = async () => {
    setFetchingUntrackedZoomMeetings(true);

    const { response, error } =
      await availabilityService.searchZoomRecordings();
    if (error) return toast.error(error);

    const results = response
      ?.map((zoomRecord) =>
        convertZoomRecordToAppAppointment({ zoomRecord, users })
      )
      .sort((a, b) => new Date(b.startDateTime) - new Date(a.startDateTime));

    setOriginalUntrackedZoomMeets(results);
    setFetchingUntrackedZoomMeetings(false);
  };

  const onAssignClick = async (zoomMeet) => {
    const {
      metaData: { appProperties },
    } = zoomMeet;

    let appointment = appProperties?.appointment;
    if (appointment) {
      setAppointmentDetailModalMeta({
        zoomMeet,
        fetchingAppointment: true,
      });
      const { response, error } = await availabilityService.search({
        filter: { _id: appointment },
      });
      if (error) {
        toast.error(error);
        setAppointmentDetailModalMeta(null);
        return;
      }
      appointment = response.results.appEvents[0];
    }

    if (appointment) {
      setAppointmentDetailModalMeta({
        zoomMeet,
        fetchingAppointment: false,
        appointment,
        editableContact: true,
      });
    } else {
      setAppointmentDetailModalMeta(null);
      setSelectContactModalMeta({ zoomMeet });
    }
  };

  const onDeleteZoomRecordClick = (zoomMeet) => {
    setDeleteUntrackedZoomMeetMeta({ zoomMeet });
  };

  const deleteZoomRecord = async () => {
    const { zoomMeet } = deleteUntrackedZoomMeetMeta;

    setDeleteUntrackedZoomMeetMeta((prevMeta) => ({
      ...prevMeta,
      showProgress: true,
    }));

    let { response, error } = await googleDriveServices.deleteFilesAndFolders(
      zoomMeet.driveFileId
    );

    if (error) {
      setDeleteUntrackedZoomMeetMeta((prevMeta) => ({
        ...prevMeta,
        showProgress: false,
      }));
      return toast.error(error);
    } else {
      const remainingUntrackedZoomMeets = originalUntrackedZoomMeets?.filter(
        (m) => String(m?.driveFileId) !== String(zoomMeet.driveFileId)
      );
      setOriginalUntrackedZoomMeets(remainingUntrackedZoomMeets);
      setDeleteUntrackedZoomMeetMeta(null);
    }
  };

  const onViewMeetRecordingClick = async (zoomMeet) => {
    let fileURL = `https://drive.google.com/file/d/${zoomMeet.driveFileId}/view`;
    window.open(fileURL);
  };

  const onContactSelect = () => {
    setContactAppointmentsModalMeta(
      JSON.parse(JSON.stringify({ ...selectContactModalMeta }))
    );
    setSelectContactModalMeta(null);
  };

  const onViewAppointmentClick = (appointment) => {
    setAppointmentDetailModalMeta({
      appointment,
    });
  };

  const handleAddRecordToAppointment = async ({ appointment }) => {
    setContactAppointmentsModalMeta({
      ...contactAppointmentsModalMeta,
      showProgress: true,
    });

    const { zoomMeet } = contactAppointmentsModalMeta;

    const requestBody = {
      driveFileId: zoomMeet.driveFileId,
      appointmentId: appointment._id,
    };

    const { response, error } =
      await availabilityService.assignRecordToAppointment(requestBody);

    if (error) {
      toast.error(error);
      setContactAppointmentsModalMeta({
        ...contactAppointmentsModalMeta,
        showProgress: false,
      });
      return;
    }

    onImportUntrackedZoomMeet({
      ...appointment,
      start: new Date(appointment?.startDateTime),
      end: new Date(appointment?.endDateTime),
      isAppEvent: true,
    });

    const remainingUntrackedZoomMeets = originalUntrackedZoomMeets?.filter(
      (m) => String(m?.driveFileId) !== String(zoomMeet?.driveFileId)
    );
    setOriginalUntrackedZoomMeets(remainingUntrackedZoomMeets);
    setContactAppointmentsModalMeta(null);
    toast.success("Record added succesfully");
  };

  const handleCreateNewAppointement = async () => {
    const { zoomMeet, contact } = contactAppointmentsModalMeta;

    const newAppointment = {
      ...zoomMeet,
      participants: zoomMeet?.participants?.map((p) => p?._id),
      contact: contact?._id,
      invitees: contact?.emails,
      appointmentType: "Zoom Meeting",
    };

    setContactAppointmentsModalMeta((prevMeta) => ({
      ...prevMeta,
      showProgress: true,
    }));

    const { response, error } = await availabilityService.create(
      newAppointment
    );

    if (error) {
      setContactAppointmentsModalMeta((prevMeta) => ({
        ...prevMeta,
        showProgress: false,
      }));
      return toast.error(error);
    }
    if (response) {
      await handleAddRecordToAppointment({ appointment: response });
    }
  };

  const handleAssignRecordToAppointment = async () => {
    const { zoomMeet, appointment } = appointmentDetailModalMeta;

    if (!appointment.contact?._id) {
      return toast.error("Please select a contact before continuing.");
    }

    setAppointmentDetailModalMeta({
      ...appointmentDetailModalMeta,
      showProgress: true,
    });

    const requestBody = {
      driveFileId: zoomMeet?.driveFileId,
      appointmentId: appointment._id,
      newContactToAssign: appointment.contact._id,
    };

    const { response, error } =
      await availabilityService.assignRecordToAppointment(requestBody);

    if (error) {
      toast.error(error);
      setAppointmentDetailModalMeta({
        ...appointmentDetailModalMeta,
        showProgress: false,
      });
      return;
    }

    onImportUntrackedZoomMeet({
      ...appointment,
      start: new Date(appointment?.startDateTime),
      end: new Date(appointment?.endDateTime),
      isAppEvent: true,
    });

    const remainingUntrackedZoomMeets = originalUntrackedZoomMeets?.filter(
      (m) => String(m?.driveFileId) !== String(zoomMeet?.driveFileId)
    );

    setOriginalUntrackedZoomMeets(remainingUntrackedZoomMeets);
    setAppointmentDetailModalMeta(null);

    toast.success("Record added succesfully");
  };

  useEffect(() => {
    const searchedResults = originalUntrackedZoomMeets.filter((meet) => {
      const { title = "", participants = [] } = meet;
      return (
        (!searchQuery ||
          title
            .toLowerCase()
            .trim()
            .includes(searchQuery.toLowerCase().trim())) &&
        participants.some((participant) =>
          checkedParticipants.includes(participant?._id)
        )
      );
    });

    setUntrackedZoomMeets(searchedResults);
  }, [originalUntrackedZoomMeets, searchQuery, checkedParticipants]);

  useEffect(() => {
    if (!isAdminOrManager(user.role) || !users?.length) return;
    getZoomRecords();
  }, [users, user]);

  return (
    <div className="">
      {fetchingUntrackedZoomMeetings ? (
        <div className="px-2">
          <Loader />
        </div>
      ) : untrackedZoomMeets?.length === 0 ? (
        <div className="p-2">
          <h6 className="mid">0 results found</h6>
        </div>
      ) : (
        untrackedZoomMeets?.map((zoomMeeting) => (
          <MeetingCard
            fetchingZoomRecording={false}
            key={zoomMeeting?.uuid}
            appointment={zoomMeeting}
            onAssignContactClick={onAssignClick}
            onViewMeetRecordingClick={onViewMeetRecordingClick}
            onDeleteMeetClick={onDeleteZoomRecordClick}
          />
        ))
      )}

      {Boolean(selectContactModalMeta) && (
        <SelectContactModal
          show={selectContactModalMeta}
          onHide={() => setSelectContactModalMeta(null)}
          contacts={contacts}
          showProgress={loadingContacts || selectContactModalMeta?.showProgress}
          setContactQuery={setContactQuery}
          onContactSelect={(contact) =>
            setSelectContactModalMeta({
              ...selectContactModalMeta,
              contact,
            })
          }
          selectedContact={selectContactModalMeta?.contact}
          showFooter
          onContactSelectClick={onContactSelect}
        />
      )}

      <ContactAppointmentModal
        show={Boolean(contactAppointmentsModalMeta)}
        onHide={() => {
          setSelectContactModalMeta(
            JSON.parse(JSON.stringify({ ...contactAppointmentsModalMeta }))
          );
          setContactAppointmentsModalMeta(null);
        }}
        contact={contactAppointmentsModalMeta?.contact}
        showProgress={contactAppointmentsModalMeta?.showProgress}
        onViewAppointmentClick={onViewAppointmentClick}
        onAddRecordToAppointmentClick={handleAddRecordToAppointment}
        onCreateNewAppointmentClick={handleCreateNewAppointement}
      />

      <AppointmentDetailModal
        show={Boolean(appointmentDetailModalMeta)}
        onHide={() => setAppointmentDetailModalMeta(null)}
        fetchingAppointment={appointmentDetailModalMeta?.fetchingAppointment}
        appointment={appointmentDetailModalMeta?.appointment}
        isFromAssignContact={appointmentDetailModalMeta?.editableContact}
        onAppointmentChange={(appointment) =>
          appointmentDetailModalMeta?.editableContact
            ? setAppointmentDetailModalMeta((prev) => ({
                ...prev,
                appointment,
              }))
            : {}
        }
        footerContent={() =>
          appointmentDetailModalMeta?.editableContact ? (
            <div className="d-flex align-items-center justify-content-end gap-2 border-top pt-1">
              <Button
                size="sm"
                variant="dark"
                disabled={
                  !appointmentDetailModalMeta?.appointment?.contact ||
                  appointmentDetailModalMeta?.showProgress
                }
                onClick={handleAssignRecordToAppointment}
              >
                <h6 className="mb-0 smallFont">{translate("assign")}</h6>
              </Button>
            </div>
          ) : (
            ""
          )
        }
      />

      <AlertModal
        show={deleteUntrackedZoomMeetMeta}
        onHide={() => setDeleteUntrackedZoomMeetMeta(null)}
        alertText={translate("are_you_sure_delete_zoom_record")}
        onDismissClick={() => setDeleteUntrackedZoomMeetMeta(null)}
        onContinueClick={deleteZoomRecord}
        showProgress={deleteUntrackedZoomMeetMeta?.showProgress}
      />
    </div>
  );
};

export default ImportZoomRecords;
