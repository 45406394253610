import React, { useEffect, useRef, useState } from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
import { Check, Check2, Pencil, PlusCircle, X } from "react-bootstrap-icons";
import useLocalization from "../../../../hooks/useLocalization";
import UnderlineButton from "../../../common/UnderlineButton";
import "./styles.scss"; // Ensure to create and import this stylesheet for custom styles.
import CircularProgressBar from "../../../common/circular-progress";

export default function WhatsappChatScreen({
  isEditing,
  savingMessageBody,
  editable,
  handleEditClick,
  handleSaveClick,
  handleCloseClick,
  message,
  onMessageChange,
  time = "12:45 PM", // You can customize the time.
  isSent = true, // Controls whether the message is sent or not.
}) {
  const { isRTL, translate } = useLocalization();
  const textareaRef = useRef(null);
  const [placeholder, setPlaceholder] = useState(""); // State for placeholder input

  // Automatically adjust the height of the textarea as the content changes
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + "px";
    }
  }, [message, isEditing]);

  // Function to insert placeholder at cursor position
  const insertPlaceholder = () => {
    if (!textareaRef.current) return;

    const { selectionStart, selectionEnd, value } = textareaRef.current;

    // Insert blank placeholder {{}}, and adjust the cursor position to be between the curly braces
    const newText =
      value.substring(0, selectionStart) +
      `{{}}` +
      value.substring(selectionEnd);

    onMessageChange(newText);

    // Update the textarea value and set the cursor position between the curly braces
    setTimeout(() => {
      textareaRef.current.focus();
      textareaRef.current.selectionStart = textareaRef.current.selectionEnd =
        selectionStart + 2; // Place cursor between {{ and }}
    }, 0);
  };

  // UseEffect to add keyboard shortcut for Cmd+I or Ctrl+I to insert placeholder only when textarea is focused
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check for Cmd+I or Ctrl+I when the textarea is focused
      if ((event.metaKey || event.ctrlKey) && event.key === "i") {
        event.preventDefault(); // Prevent default behavior (like opening the browser dev tools)
        insertPlaceholder();
      }
    };

    const textarea = textareaRef.current;

    if (textarea) {
      // Attach keydown event listener only when the textarea is focused
      textarea.addEventListener("keydown", handleKeyDown);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (textarea) {
        textarea.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [message]);

  return (
    <div className="whatsapp-chat-screen-wrapper rounded p-2">
      <div className={"d-flex justify-content-end"}>
        {editable && (
          <div
            className={`d-flex align-items-center flex-wrap justify-content-${
              !isRTL ? "end" : "start"
            } flex-grow-1`}
          >
            {!isEditing ? (
              savingMessageBody ? (
                <div className="d-flex align-items-center gap-1">
                  <CircularProgressBar variant="success" size={12} />{" "}
                  <h6 className="mb-0 text-success smallFont">Saving...</h6>
                </div>
              ) : (
                <UnderlineButton
                  Icon={Pencil}
                  onClick={handleEditClick}
                  text={translate("edit_message")}
                  fontSize="smallFont"
                />
              )
            ) : (
              <>
                <UnderlineButton
                  fontSize="smallFont"
                  Icon={PlusCircle}
                  iconSize={14}
                  variant="dark"
                  onClick={insertPlaceholder}
                  text={translate("insert_placeholder")}
                />
                <UnderlineButton
                  fontSize="smallFont"
                  Icon={Check}
                  iconSize={14}
                  variant="success"
                  onClick={handleSaveClick}
                  text={translate("save")}
                />
                <UnderlineButton
                  fontSize="smallFont"
                  iconSize={14}
                  Icon={X}
                  variant="danger"
                  onClick={handleCloseClick}
                  text={translate("close")}
                />
              </>
            )}
          </div>
        )}
      </div>
      <div
        className={`whatsapp-chat-screen px-3 gap-2 justify-content-${
          isRTL ? "start" : "end"
        }`}
      >
        <div className="chat-bubble">
          <textarea
            ref={textareaRef}
            value={message}
            disabled={!isEditing}
            onChange={(e) => onMessageChange(e.target.value)}
            className="chat-bubble-editing"
            rows={1} // Initial rows set to 1
          />

          <div
            className="chat-bubble-viewing"
            style={{ visibility: "collapse", height: 0, overflow: "hidden" }}
          >
            {message}
          </div>

          <div className="chat-footer">
            <div className="d-flex align-items-center">
              <span className="time-text">{time}</span>
              {isSent && (
                <span className="double-tick">
                  <Check2 size={14} className="tick-icon" />
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
