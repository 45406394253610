import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormLabel, Row } from "react-bootstrap";
import useLocalization from "../../hooks/useLocalization";
import HorizontalProgress from "../common/HorizontalProgress";
import CustomMultiSelect from "../common/CustomMultiSelect";

const languageOptions = [
  { label: "English", value: "en" },
  { label: "Hebrew", value: "he" },
];

const defaultValue = {
  templateName: "",
  language: "he",
};

export default function AddTemplateForm({
  editMode,
  initialValue,
  showProgress,
  onSubmit,
}) {
  const [formValues, setFormValues] = useState("");
  const [errors, setErrors] = useState(null);
  const { translate } = useLocalization();

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (formValues.templateName.trim()) {
      setErrors(null);
      onSubmit(formValues);
    } else {
      setErrors("Template name is required");
    }
  };

  useEffect(() => {
    setFormValues(
      initialValue
        ? {
            templateName: initialValue?.templateName,
            language: initialValue?.language,
          }
        : defaultValue
    );
  }, [initialValue]);

  return (
    <Form onSubmit={handleFormSubmit}>
      <Row className="m-0">
        <Col sm={8}>
          <Form.Group className="mb-1">
            <Form.Label className="mid mb-1">
              {translate("template_name")}
            </Form.Label>
            <Form.Control
              size="sm"
              type="text"
              placeholder={translate("enter_template_name")}
              value={formValues.templateName}
              onChange={(e) =>
                setFormValues({ ...formValues, templateName: e.target.value })
              }
            />
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group className="mb-1">
            <Form.Label className="mid mb-1">
              {translate("language")}
            </Form.Label>

            <CustomMultiSelect
              items={languageOptions}
              selectedItems={formValues.language}
              onChange={(language) =>
                setFormValues({ ...formValues, language })
              }
              closeMenuOnSelect
              isMulti={false}
            />
          </Form.Group>
        </Col>
      </Row>

      {errors && <h6 className="smallFont text-danger mx-2">{errors}</h6>}
      {showProgress && <HorizontalProgress text={"Please wait..."} />}
      <div className="text-end mt-2 border-top py-1">
        <Button
          className="text-white"
          variant="success"
          disabled={showProgress}
          size="sm"
          onClick={handleFormSubmit}
        >
          {translate("add")}
        </Button>
      </div>
    </Form>
  );
}
