import { useEffect, useMemo, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { PlusCircleFill } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { ABORT_ERROR } from "../../../helpers/api";
import { DEFAULT_PAGE_SIZE } from "../../../helpers/constants";
import { getAutomationsTableColumns } from "../../../helpers/dataSheetConstants";
import { getFormattedDate, updateItemsInArray } from "../../../helpers/global";
import useLocalization from "../../../hooks/useLocalization";
import { automationService } from "../../../services/automationService";
import AlertModal from "../../common/AlertModal";
import FloatingButton from "../../common/FloatingButton";
import DataTable from "../../common/data-table/DataTable";
import AddEditAutomationsModal from "./AddEditAutomationsModal";
import useAppChoices from "../../../hooks/useAppChoices";

const initialPageInfo = {
  pageNumber: 1,
  totalPages: 1,
};

const BrowseAutomations = ({
  activeTrailLists,
  campaigns,
  whatsappTemplates,
}) => {
  const abortControllerRef = useRef(null);
  const users = useAppChoices("users");
  const { translate } = useLocalization();
  const [fetchingFirstPageAutomations, setFetchingFirstPageAutomations] =
    useState(false);
  const [fetchingMoreAutomations, setFetchingMoreAutomations] = useState(false);

  //original automations array
  const [automations, setAutomations] = useState([]);

  const [toLoadPageInfo, setToLoadPageInfo] = useState(initialPageInfo);
  const [loadedPageInfo, setLoadedPageInfo] = useState();

  const [automationModalMeta, setAutomationModalMeta] = useState(null);
  const [automationseleteModalMeta, setAutomationDeleteModalMeta] =
    useState(null);

  const onAddNewAutomationClick = () => {
    setAutomationModalMeta({});
  };

  const onEditAutomationClick = (automation) => {
    setAutomationModalMeta({
      initialValues: {
        ...automation,
        facebookCampaignIds: automation.facebookCampaigns.map((c) => c?.id),
        date: getFormattedDate(new Date(automation.date)),
      },
      editMode: true,
      editingAutomation: automation,
    });
  };

  const onDeleteAutomationClick = (automation) => {
    setAutomationDeleteModalMeta({ automation });
  };

  const tableColumns = useMemo(
    () =>
      getAutomationsTableColumns(
        onEditAutomationClick,
        onDeleteAutomationClick,
        users,
        translate
      ),
    [onEditAutomationClick, onDeleteAutomationClick, users]
  );

  const fetchAutomations = async () => {
    if (!toLoadPageInfo) return;

    // If an old API call is in progress, abort it
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const pageToFetch = toLoadPageInfo?.pageNumber;
    const loadingMoreAutomations = pageToFetch > 1;

    if (loadingMoreAutomations) {
      setFetchingMoreAutomations(true);
    } else {
      setFetchingFirstPageAutomations(true);
    }

    const controller = new AbortController();
    const { signal } = controller;

    abortControllerRef.current = controller;

    const requestBody = {
      pageSize: DEFAULT_PAGE_SIZE,
      pageNumber: pageToFetch,
    };

    const { response, error } = await automationService.getAutomations(
      requestBody,
      signal
    );

    if (error === ABORT_ERROR) return;

    setFetchingMoreAutomations(false);
    setFetchingFirstPageAutomations(false);

    if (error) {
      return toast.error(error);
    }

    const { pageNumber, totalPages, results = [] } = response;
    setLoadedPageInfo({ totalPages, pageNumber });
    setAutomations((prevAutomations) =>
      loadingMoreAutomations ? [...prevAutomations, ...results] : results
    );
  };

  const loadMoreData = () => {
    if (
      !loadedPageInfo ||
      fetchingFirstPageAutomations ||
      fetchingMoreAutomations
    )
      return;

    if (loadedPageInfo.totalPages < loadedPageInfo.pageNumber) return;

    setToLoadPageInfo({
      ...loadedPageInfo,
      pageNumber: loadedPageInfo.pageNumber + 1,
    });
  };

  useEffect(() => {
    fetchAutomations();
  }, [toLoadPageInfo]);

  const onAutomationChange = (automation) => {
    setAutomations((prevAutomations) => {
      const updatedAutomations = updateItemsInArray(prevAutomations, [
        automation,
      ]);
      return updatedAutomations;
    });
  };

  const onAutomationSubmit = async (automation) => {
    const { editMode, editingAutomation } = automationModalMeta;
    setAutomationModalMeta((meta) => ({ ...meta, showProgress: true }));

    const { response, error } = editMode
      ? await automationService.updateAutomation(
          editingAutomation._id,
          automation
        )
      : await automationService.createAutomation(automation);
    setAutomationModalMeta((meta) => ({ ...meta, showProgress: false }));

    if (error) {
      return toast.error(error);
    }

    setAutomationModalMeta(null);
    onAutomationChange(response);
    toast.success(
      editMode
        ? "Successfully updated automation"
        : "Successfully added automation"
    );
  };

  const deleteAutomation = async () => {
    const { automation } = automationseleteModalMeta;
    setAutomationDeleteModalMeta((meta) => ({ ...meta, showProgress: true }));

    const { response, error } = await automationService.deleteAutomation(
      automation
    );
    setAutomationDeleteModalMeta((meta) => ({ ...meta, showProgress: false }));

    if (error) {
      return toast.error(error);
    }

    setAutomationDeleteModalMeta(null);
    setAutomations((automations) =>
      automations.filter((p) => p._id !== automation._id)
    );
    toast.success("Successfully deleted automation");
  };

  return (
    <>
      <Container fluid className={"px-2 py-3 h-100"}>
        <div className="border rounded p-2 bg-white">
          <h6 className="mb-3 xlarge fw-bold">
            {translate("automations")} ({automations.length}):
          </h6>
          <DataTable
            rowKey={"_id"}
            columns={tableColumns}
            data={automations}
            onBottomReached={loadMoreData}
            loadingMoreData={fetchingMoreAutomations}
            loadingFirstPageData={fetchingFirstPageAutomations}
            allowFilter={false}
            allowSort={false}
          />
        </div>
      </Container>
      <FloatingButton
        className="text-white"
        Icon={PlusCircleFill}
        variant="success"
        style={{ right: 120 }}
        text={translate("add_new_automation")}
        onClick={onAddNewAutomationClick}
      />
      <AddEditAutomationsModal
        show={Boolean(automationModalMeta)}
        initialValues={automationModalMeta?.initialValues}
        editMode={automationModalMeta?.editMode}
        activeTrailLists={activeTrailLists}
        campaigns={campaigns}
        whatsappTemplates={whatsappTemplates}
        onHide={() => setAutomationModalMeta(null)}
        showProgress={automationModalMeta?.showProgress}
        onSubmit={onAutomationSubmit}
      />
      {automations.length > 0 && (
        <AlertModal
          show={Boolean(automationseleteModalMeta)}
          onHide={() => setAutomationDeleteModalMeta(false)}
          onDismissClick={() => setAutomationDeleteModalMeta(false)}
          alertText={translate("are_you_sure_you_want_to_delete_automation", {
            eventName: automationseleteModalMeta?.automation?.name,
          })}
          showProgress={automationseleteModalMeta?.showProgress}
          onContinueClick={deleteAutomation}
        />
      )}
    </>
  );
};

export default BrowseAutomations;
