import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import React, { useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  FormLabel,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { Plus, Trash } from "react-bootstrap-icons";
import * as Yup from "yup";
import { WEBSITE } from "../../helpers/regex";
import useLocalization from "../../hooks/useLocalization";
import SalespersonDropdown from "../automation-events/common/SalespersonDropdown";
import StatusDropdown from "../automation-events/common/StatusDropdown";
import LeadSourceDropdown from "../automation-events/common/LeadSourceDropdown";
import ActiveTrailDropdown from "../automation-events/common/ActiveTrailDropdown";
import FacebookCampaignDropdown from "../automation-events/common/FacebookCampaignDropdown";
import WhatsappTemplateTable from "../automation-events/common/WhatsappTemplateTable";
import AddEditWhatsappTemplateModal from "../automation-events/common/AddEditWhatsappTemplateModal";

// Yup validation schema updated with new requirements
const validationSchema = Yup.object().shape({
  key: Yup.string().required("Key is required"),
  status: Yup.string().required("Status is required"),
  whatsappCampaigns: Yup.array().of(
    Yup.object().shape({
      template: Yup.string().required("Template is required"),
    })
  ),
  exceptions: Yup.array().of(
    Yup.object().shape({
      value: Yup.string().when("key", {
        is: "Website",
        then: (schema) =>
          schema
            .matches(WEBSITE, "URL is not valid")
            .required("url is required"),
        otherwise: (schema) => schema.required("Value is required"),
      }),
      status: Yup.string().required("Status is required"),
    })
  ),
});

const defaultValues = {
  status: "",
  exceptions: [
    {
      value: "",
      status: "",
      salespersons: [],
    },
  ],
};

const EditDefaultStatusForm = ({
  initialValues,
  activeTrailOptions,
  showProgress = false,
  onSubmit,
  campaigns,
  whatsappTemplates,
}) => {
  const { translate } = useLocalization();
  const [addEditWhatsappCampaignMeta, setAddEditWhatsappCampaignMeta] =
    useState(null);
  return (
    <Formik
      initialValues={initialValues ?? defaultValues}
      validationSchema={validationSchema}
      onSubmit={(value) => {
        const valueToSubmit = {
          ...value,
        };
        onSubmit(valueToSubmit);
      }}
    >
      {({ setFieldValue, values, submitForm, errors }) => {
        return (
          <Form className="p-2">
            <h6 className="xxlarge underline" style={{ fontWeight: 600 }}>
              {values?.key}
            </h6>
            <Row>
              <Col xs={12} md={3}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("status")}
                  </FormLabel>
                  <StatusDropdown
                    value={values.status}
                    onChange={(status) => setFieldValue("status", status)}
                  />
                  {errors["status"] && (
                    <div className="text-danger smallFont">
                      {errors["status"]}
                    </div>
                  )}
                </FormGroup>
              </Col>{" "}
              <Col xs={12} md={3}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("lead_source")}
                  </FormLabel>
                  <LeadSourceDropdown
                    value={values.leadSource}
                    onChange={(leadSource) =>
                      setFieldValue("leadSource", leadSource)
                    }
                  />
                  {errors["leadSource"] && (
                    <div className="text-danger smallFont">
                      {errors["leadSource"]}
                    </div>
                  )}
                </FormGroup>
              </Col>{" "}
              <Col xs={12} md={3}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    Active Trail Groups
                  </FormLabel>{" "}
                  <ActiveTrailDropdown
                    activeTrails={activeTrailOptions}
                    value={values?.activeTrailLists?.map((l) => Number(l.id))}
                    onChange={(aT) => {
                      setFieldValue(
                        "activeTrailLists",
                        aT.map((a) => ({
                          name: activeTrailOptions.find((o) => o.id == a)?.name,
                          id: a,
                        }))
                      );
                    }}
                  />
                  <ErrorMessage
                    name="activeTrailLists"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>{" "}
              <Col xs={12} md={3}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("salesperson")}
                  </FormLabel>
                  <SalespersonDropdown
                    value={values?.salespersons}
                    onChange={(selectedOptions) =>
                      setFieldValue(`salespersons`, selectedOptions)
                    }
                  />

                  <ErrorMessage
                    name="salespersons"
                    component="div"
                    className="text-danger mid"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={6}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">Whatsapp campaigns</FormLabel>
                  <WhatsappTemplateTable
                    errors={errors.whatsappCampaigns || []}
                    whatsappTemplates={whatsappTemplates}
                    values={values.whatsappCampaigns}
                    onChange={(whatsappCampaigns) =>
                      setFieldValue(`whatsappCampaigns`, whatsappCampaigns)
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={12}>
                <FormGroup className="mb-2">
                  <FormLabel className="mid mb-1">
                    {translate("exceptions")}
                  </FormLabel>
                  <Table bordered striped className="mb-0">
                    <thead>
                      <tr className="mid">
                        <th>Exception</th>
                        <th style={{ width: 120 }}>Status</th>
                        <th>Lead source</th>
                        <th>Salesperson</th>
                        <th>Active Trail Groups</th>
                        <th>Whatsapp campaigns</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <FieldArray
                        name="exceptions"
                        render={(arrayHelpers) => (
                          <>
                            {values.exceptions &&
                              values.exceptions.length > 0 &&
                              values.exceptions.map((e, index) => (
                                <tr key={`${index}`}>
                                  <td>
                                    {" "}
                                    {initialValues?.key ===
                                    "Facebook Leadform" ? (
                                      <FacebookCampaignDropdown
                                        campaigns={campaigns.map((option) => ({
                                          value: option.id,
                                          label: option.name,
                                        }))}
                                        value={
                                          values?.exceptions?.[index]?.value
                                        }
                                        onChange={(selectedOptions) =>
                                          setFieldValue(
                                            `exceptions.${index}.value`,
                                            selectedOptions
                                          )
                                        }
                                        isMulti={false}
                                      />
                                    ) : (
                                      <Field
                                        name={`exceptions.${index}.value`}
                                        type="text"
                                        className="form-control form-control-sm"
                                        size="sm"
                                      />
                                    )}
                                    <ErrorMessage
                                      name={`exceptions.${index}.value`}
                                      component="div"
                                      className="text-danger smallFont"
                                    />
                                  </td>
                                  <td>
                                    {" "}
                                    <StatusDropdown
                                      value={
                                        values?.exceptions?.[index]?.status
                                      }
                                      onChange={(status) =>
                                        setFieldValue(
                                          `exceptions.${index}.status`,
                                          status
                                        )
                                      }
                                    />
                                    <ErrorMessage
                                      name={`exceptions.${index}.status`}
                                      component="div"
                                      className="text-danger smallFont"
                                    />
                                  </td>
                                  <td>
                                    <LeadSourceDropdown
                                      value={
                                        values?.exceptions?.[index]?.leadSource
                                      }
                                      onChange={(leadSource) =>
                                        setFieldValue(
                                          `exceptions.${index}.leadSource`,
                                          leadSource
                                        )
                                      }
                                    />
                                    <ErrorMessage
                                      name={`exceptions.${index}.leadSource`}
                                      component="div"
                                      className="text-danger smallFont"
                                    />
                                  </td>
                                  <td>
                                    {" "}
                                    <SalespersonDropdown
                                      value={
                                        values?.exceptions?.[index]
                                          ?.salespersons
                                      }
                                      onChange={(selectedOptions) =>
                                        setFieldValue(
                                          `exceptions.${index}.salespersons`,
                                          selectedOptions
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <ActiveTrailDropdown
                                      activeTrails={activeTrailOptions}
                                      value={values?.exceptions?.[
                                        index
                                      ]?.activeTrailLists?.map((l) =>
                                        Number(l.id)
                                      )}
                                      onChange={(aT) => {
                                        setFieldValue(
                                          `exceptions.${index}.activeTrailLists`,
                                          aT.map((a) => ({
                                            name: activeTrailOptions.find(
                                              (o) => o.id == a
                                            )?.name,
                                            id: a,
                                          }))
                                        );
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Button
                                      size="sm"
                                      variant="outline-dark"
                                      className="mt-1 d-flex align-items-center"
                                      onClick={() =>
                                        setAddEditWhatsappCampaignMeta({
                                          value:
                                            values?.exceptions?.[index]
                                              ?.whatsappCampaigns,
                                        })
                                      }
                                    >
                                      <h6 className="mb-0 smallFont">
                                        Manage{" "}
                                        {/* {
                                          values?.exceptions?.[index]
                                            ?.whatsappCampaigns.length
                                        }{" "} */}
                                        Campaigns
                                      </h6>
                                    </Button>
                                    {Boolean(addEditWhatsappCampaignMeta) && (
                                      <AddEditWhatsappTemplateModal
                                        show={addEditWhatsappCampaignMeta}
                                        onHide={() =>
                                          setAddEditWhatsappCampaignMeta(null)
                                        }
                                        whatsappTemplates={whatsappTemplates}
                                        initialValue={
                                          addEditWhatsappCampaignMeta?.value
                                        }
                                        onSubmit={(whatsappCampaigns) => {
                                          setFieldValue(
                                            `exceptions.${index}.whatsappCampaigns`,
                                            whatsappCampaigns
                                          );
                                          setAddEditWhatsappCampaignMeta(null);
                                        }}
                                      />
                                    )}
                                  </td>
                                  <td align="center">
                                    <Button
                                      size="sm"
                                      variant="outline-danger"
                                      className="px-1 py-0"
                                      onClick={() => arrayHelpers.remove(index)}
                                    >
                                      <Trash />
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            <tr>
                              <td colSpan={7} align="right">
                                <Button
                                  size="sm"
                                  variant="primary"
                                  className="d-flex align-items-center gap-1 text-white"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      value: "",
                                      status: "",
                                      whatsappCampaigns: [],
                                    })
                                  }
                                  disabled={showProgress}
                                >
                                  <Plus />{" "}
                                  <h6 className="mb-0 smallFont">
                                    {translate("add")}
                                  </h6>
                                </Button>{" "}
                              </td>
                            </tr>
                          </>
                        )}
                      />
                    </tbody>
                  </Table>
                </FormGroup>
              </Col>
            </Row>
            <div className="text-end">
              <hr className="mt-1 mb-2" />
              <Button
                className=""
                variant="success text-white"
                onClick={submitForm}
                size="sm"
                disabled={showProgress}
              >
                {showProgress ? <Spinner size="sm" /> : translate("save")}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditDefaultStatusForm;
