import { makeRESTApiRequests } from "../helpers/api";
import { ENDPOINTS } from "../helpers/constants";

export const whatsappCampaignService = {
  getWhatsappCampaigns: async (reqBody = {}, signal) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.GET_FB_CAMPAIGNS,
      requestBody: reqBody,
      method: "POST",
      signal,
    });

    return { response, error };
  },
  createWhatsappCampaign: async (reqBody = {}) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.CREATE_WHATSAPP_CAMPAIGN,
      requestBody: reqBody,
      method: "POST",
    });

    return { response, error };
  },
  updateWhatsappCampaign: async (campaignID, reqBody = {}) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.WHATSAPP_CAMPAIGN_WITH_ID(campaignID),
      requestBody: reqBody,
      method: "PUT",
    });

    return { response, error };
  },
  deleteWhatsappCampaign: async (campaignID) => {
    const { response, error } = await makeRESTApiRequests({
      endpoint: ENDPOINTS.WHATSAPP_CAMPAIGN_WITH_ID(campaignID),
      method: "DELETE",
    });

    return { response, error };
  },
};
