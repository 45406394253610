import React, { useState } from "react";
import { Button } from "react-bootstrap";
import useContacts from "../../../hooks/useContacts";
import UnassignedAppointments from "./UnassignedAppointments";
import UntrackedZoomMeeting from "./UntrackedZoomMeeting";
import ImportZoomRecords from "./ImportZoomRecords";

const tabs = [
  { label: "Untracked Zoom Meetings", key: "untracked_zoom_meets" },
  { label: "Unassigned App Meetings", key: "unassigned_app_meets" },
  { label: "Import zoom records", key: "import_zoom_records" },
];

const Tab = ({ tabs, selectedTab, onTabChange }) => {
  return (
    <div className="d-flex align-items-center gap-1">
      {tabs?.map(({ label, key }) => (
        <Button
          key={key}
          size="sm"
          variant={key === selectedTab ? "dark" : "outline-dark"}
          className="d-flex align-items-center rounded-0"
          onClick={() => onTabChange(key)}
        ></Button>
      ))}
    </div>
  );
};

const UntrackedAppointments = ({
  collapseSidebar,
  closeImportMode,
  searchQuery,
  checkedParticipants,
  appointmentTypeFilter,
  onImportUntrackedZoomMeet,
  onAssignContactToAppointment,
  onNavigateToDate,
  onDeleteApointment,
  bodyHeight,
  handleAppointmentClick,
}) => {
  const { contacts, loadingContacts, setContactQuery } = useContacts();
  return (
    <div
      className="border border-top-0 overflow-auto position-relative"
      style={{ height: bodyHeight }}
    >
      <div
        className="d-flex align-items-center gap-2 position-sticky bg-white border-bottom"
        style={{ top: 0, padding: "9px 6px" }}
      >
        {collapseSidebar()}
        <h6 className="mb-0 fw-bold">Zoom records</h6>
      </div>

      <ImportZoomRecords
        contacts={contacts}
        loadingContacts={loadingContacts}
        setContactQuery={setContactQuery}
        searchQuery={searchQuery}
        checkedParticipants={checkedParticipants}
        appointmentTypeFilter={appointmentTypeFilter}
        onImportUntrackedZoomMeet={onImportUntrackedZoomMeet}
        handleAppointmentClick={handleAppointmentClick}
      />
    </div>
  );
};

export default UntrackedAppointments;
