import React, { useEffect, useState } from "react";
import BrowseEvents from "./events/BrowseEvents";
import BrowseAutomations from "./automation/BrowseAutomations";
import { inboundSourceDefaultsStatusService } from "../../services/inboundSourceDefaultsStatusService";
import { Spinner } from "react-bootstrap";
import InboundSourceDefaultStatus from "./inbound-source-status/InboundSourceDefaultStatus";
import { miscService } from "../../services/miscService";
import Loader from "../common/Loader";
import { toast } from "react-toastify";
import { whatsappTemplateService } from "../../services/whatsappTemplateService";

const AutomationAndEvents = () => {
  const [campaigns, setCampaigns] = useState([]);
  const [activeTrailLists, setActiveTrailLists] = useState([]);
  const [fetchingActiveTrailAndCampaigns, setFetchingActiveTrailAndCampaigns] =
    useState(false);
  const [whatsappTemplates, setWhatsappTemplates] = useState([]);
  const [fetchingWhatsappTemplates, setFetchingWhatsappTemplates] =
    useState(true);

  const fetchTemplates = async () => {
    setFetchingWhatsappTemplates(true);

    let requestBody = {
      filter: { status: "Approved" },
    };
    let { response, error } =
      await whatsappTemplateService.getWhatsappTemplates(requestBody);

    if (error) {
      toast.error(error);
      return;
    }
    const { results } = response;
    setWhatsappTemplates(results);
    setFetchingWhatsappTemplates(false);
  };

  const getActiveTrailsAndCampaigns = async () => {
    setFetchingActiveTrailAndCampaigns(true);

    const { response: activeTrailLists, error: activeTrailError } =
      await miscService.getActiveTrailLists();

    const { response: campaignsLists, error: campaignError } =
      await miscService.getFacebookCampaigns();

    setFetchingActiveTrailAndCampaigns(false);

    if (activeTrailError || campaignError) {
      return toast.error(activeTrailError || campaignError);
    }

    setCampaigns(campaignsLists);
    setActiveTrailLists(activeTrailLists);
  };

  useEffect(() => {
    getActiveTrailsAndCampaigns();
    fetchTemplates();
  }, []);

  return fetchingWhatsappTemplates || fetchingActiveTrailAndCampaigns ? (
    <div className="px-3">
      <Loader />
    </div>
  ) : (
    <div className="pb-5">
      <BrowseAutomations
        campaigns={campaigns}
        activeTrailLists={activeTrailLists}
        whatsappTemplates={whatsappTemplates}
      />
      <BrowseEvents
        campaigns={campaigns}
        activeTrailLists={activeTrailLists}
        whatsappTemplates={whatsappTemplates}
      />
      <InboundSourceDefaultStatus
        campaigns={campaigns}
        activeTrailLists={activeTrailLists}
        whatsappTemplates={whatsappTemplates}
      />
    </div>
  );
};

export default AutomationAndEvents;
