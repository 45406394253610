import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { appConfigurationService } from "../../services/appConfigurationService";
import { filterServices } from "../../services/filterServices";
import { tagsServices } from "../../services/tagsServices";
import CompoundFilter from "./compound-filter";
import AppointmentDefaultType from "./event-type/AppointmentDefaultType";
import Tags from "./tags/Tags";
import WhatsappTemplates from "./whatsapp-message-template/WhatsappTemplates";

const AppConfiguration = () => {
  const [appointmentDefaultTypes, setAppointmentDefaultTypes] = useState([]);
  const [fetchingAppointmentTypes, setFetchingAppointmentTypes] =
    useState(true);
  const [tags, setTags] = useState([]);
  const [fetchingTags, setFetchingTags] = useState(true);
  const [filterData, setFilterData] = useState([]);
  const [fetchingFilter, setFetchingFilter] = useState(true);

  const fetchAppointmentDefaultTypes = async () => {
    try {
      setFetchingAppointmentTypes(true);
      const { response, error } =
        await appConfigurationService.getAppointmentDefalutType();
      if (error) {
        return toast.error(error);
      }
      setAppointmentDefaultTypes(response);
    } catch (error) {
      console.log(error);
    } finally {
      setFetchingAppointmentTypes(false);
    }
  };

  const fetchTags = async () => {
    try {
      setFetchingTags(true);
      const { response, error } = await tagsServices.getTags();
      if (error) {
        return toast.error(error);
      }
      setTags(response);
    } catch (error) {
      console.log(error);
    } finally {
      setFetchingTags(false);
    }
  };

  const fetchPresetFilters = async () => {
    try {
      setFetchingFilter(true);
      const { response, error } = await filterServices.getFilters();
      if (response) {
        setFilterData(response);
      }
      if (error) {
        toast.error(error);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFetchingFilter(false);
    }
  };

  useEffect(() => {
    fetchPresetFilters();
    fetchAppointmentDefaultTypes();
    fetchTags();
  }, []);

  return fetchingAppointmentTypes || fetchingFilter || fetchingTags ? (
    <div className="text-center mt-5">
      <Spinner />
    </div>
  ) : (
    <div className="m-3 pb-2">
      <Row className="">
        <Col sm={12} md={6} lg={6}>
          <CompoundFilter
            filterData={filterData}
            setFilterData={setFilterData}
            tags={tags}
          />
        </Col>
        <Col sm={12} md={6} lg={6}>
          <Tags className={"h-100"} tags={tags} setTags={setTags} />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col sm={12}>
          <AppointmentDefaultType
            appointmentDefaultTypes={appointmentDefaultTypes}
            setAppointmentDefaultTypes={setAppointmentDefaultTypes}
          />
        </Col>
        <Col sm={12} className="mt-4">
          <WhatsappTemplates />
        </Col>
      </Row>
    </div>
  );
};

export default AppConfiguration;
