import { snakeCase } from "lodash";
import React from "react";
import { FormGroup } from "react-bootstrap";
import { Envelope, PersonCircle, Telephone } from "react-bootstrap-icons";
import { contactStatusColor } from "../../helpers/global";
import useLocalization from "../../hooks/useLocalization";
import useContactAndDealPopup from "../../hooks/useContactAndDealPopup";

const ViewContactButton = ({ contact, onContactChange }) => {
  let { translate } = useLocalization();
  const { openContactPopup } = useContactAndDealPopup();

  const onViewContactClick = () => {
    openContactPopup(contact?._id);
  };

  return (
    <div className="bg-white">
      <FormGroup className="mb-2">
        <h6 className="mid mt-1 mb-2">{translate("contact")}</h6>
        <div
          className="border rounded w-100 p-1 d-flex align-items-center justify-content-between hover"
          onClick={onViewContactClick}
        >
          <div className="w-100">
            <div className="d-flex  justify-content-between">
              <div className="d-flex align-items-center">
                {" "}
                <PersonCircle size={15} />{" "}
                <h6 className="mb-0 mx-1 mid fw-bold">
                  {contact?.firstName} {contact?.lastName}
                </h6>
              </div>
              <div
                style={{ fontSize: 10 }}
                className={`bg-${contactStatusColor(
                  contact
                )} px-2 py-0 text-white rounded`}
              >
                {translate(snakeCase(contact.status))}
              </div>
            </div>
            <div className="d-flex align-items-center mt-1">
              <Envelope size={15} />{" "}
              <h6 className="mb-0 mx-1 mid">{contact?.emails?.join(", ")}</h6>{" "}
              <h6 className="mb-0 mx-1">|</h6> <Telephone size={15} />
              <h6 className="mb-0 mx-1 mid">
                {contact?.phoneNumbers?.join(", ")}
              </h6>
            </div>
          </div>
        </div>
      </FormGroup>
    </div>
  );
};

export default ViewContactButton;
