import React, { useMemo } from "react";
import DateRangePicker from "./DateRangePicker";
import CustomMultiSelect from "./CustomMultiSelect";
import useAuth from "../../hooks/useAuth";
import { isAdminOrManager } from "../../helpers/session";
import UserDropdown from "./UserDropdown";
const UserAndDateRangePicker = ({
  users,
  selectedUser = [],
  onUserChange,
  dateRange,
  onDateRangeChange,
  className = "d-flex justify-content-end",
  isRTL,
}) => {
  const { user } = useAuth();
  return (
    <div className={className}>
      {isAdminOrManager(user?.role) && (
        <div style={{ minWidth: 200, maxWidth: 350 }} className="m-1">
          <UserDropdown
            selecteduser={selectedUser}
            onChange={onUserChange}
            maxItemCustomMessage={(length) => `+ ${length} more`}
            height="26px"
            maxToShow={5}
            isMultiSelect
            closeMenuOnSelect={false}
          />
        </div>
      )}

      <DateRangePicker
        value={dateRange}
        onChange={(ranges) => {
          onDateRangeChange(ranges);
        }}
        className="m-1"
        isRTL={isRTL}
      />
    </div>
  );
};

export default UserAndDateRangePicker;
