import React from "react";
import CustomMultiSelect from "../../common/CustomMultiSelect";

const ActiveTrailDropdown = ({ activeTrails = [], value = "", onChange }) => {
  return (
    <CustomMultiSelect
      items={activeTrails.map((aT) => ({
        label: aT.name,
        value: aT.id,
      }))}
      isMulti
      selectedItems={value}
      height="30px"
      onChange={onChange}
      placeholder="Select"
    />
  );
};

export default ActiveTrailDropdown;
